import * as React from 'react';
import { graphql } from 'gatsby';
import { Grid, Container } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ContentfulRentaFija } from '../../types/graphql-types';
import FlashLayout from '../components/FlashLayout';
import IconTitle from '../components/IconTitle';
import { useRentaFijaPost } from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';
import options from '../utils/getRichTextOptions';
import Index from '../assets/icons/icon-Index.svg';
import LightboxCarousel from '../components/LightboxCarousel';
import Airtable from '../components/Airtable';
import { Table as TableProps } from '../models/Table';
import { RentaFija as RentaFijaProps } from '../models/RentaFija';
import PDFPreview from '../components/PdfPreview';
import StickyBox from 'react-sticky-box';
import { PostPreview as PostPreviewProps } from '../models/PostPreview';
import PostLayout from '../components/PostLayout';
import { renderRichText } from "gatsby-source-contentful/rich-text"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';

interface Props {
  data: {
    contentfulRentaFija: ContentfulRentaFija;
  };
}

export const Head = ({data}) => {
  const post: RentaFijaProps = useRentaFijaPost(data?.contentfulRentaFija);
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function RentaFija({ data }: Props): JSX.Element {
  const color = getCategoryColor('general');
  const post: RentaFijaProps = useRentaFijaPost(data?.contentfulRentaFija);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const postPreview = (): PostPreviewProps => {
    const {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      content,
      authorObject,
    } = post;
    return {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      content,
      authorObject,
    };
  };

  const content = (
    <>
      <>
        <h2>{post.description}</h2>
      </>
      <Grid container spacing={4}>
        { post.tables &&
        <StickyBox
          offsetTop={60}
          style={mobile ? { zIndex: -1, width: '100%' } : { width: '350px' }}
        >
          {post.tables &&
            post.tables.map((tabla: TableProps) => {
              if (tabla.tipo == 'tarjeta') {
                //tarjeta
                return (
                  <Container>
                    <IconTitle icon={Index} title={tabla.titulo}>
                      <Airtable
                        src={tabla.tabla}
                        description={tabla.descripcion}
                        card={true}
                      />
                    </IconTitle>
                  </Container>
                );
              }
            })}
        </StickyBox>
        }
        <Grid
          item
          xs={12}
          sm={12}
          md={post.tables ? 6 : 12}
          style={{ background: 'white', marginTop: 20 }}
        >
          {post.text && renderRichText(post.text, options)}
          {post.images && <LightboxCarousel images={post.images} />}
          {post.pdf && <PDFPreview pdf={post.pdf} />}
          {post.tables &&
            post.tables.map((tabla: TableProps) => {
              if (tabla.tipo == '50%') {
                return (
                  <>
                    <IconTitle icon={Index} title={tabla.titulo}>
                      <Airtable
                        src={tabla.tabla}
                        description={tabla.descripcion}
                        card={false}
                      />
                    </IconTitle>
                  </>
                );
              }
            })}
        </Grid>
      </Grid>
      <>
        {post.tables &&
          post.tables.map((tabla: TableProps) => {
            if (tabla.tipo == '100%') {
              return (
                <>
                  <Grid item xs={12} sm={12} md={6}></Grid>
                  <IconTitle icon={Index} title={tabla.titulo}>
                    <Airtable
                      src={tabla.tabla}
                      description={tabla.descripcion}
                      card={false}
                    />
                  </IconTitle>
                </>
              );
            }
          })}
      </>
    </>
  );

  const isClient = useIsClient();

  if(isClient){

    return post?.fluid ? (
      <PostLayout post={postPreview()} color={color}>
        {content}
      </PostLayout>
    ) : (
      <FlashLayout post={post} color={color}>
        {content}
      </FlashLayout>
    );
  }else{
    return(<></>)
  }

}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulRentaFija(slug: { eq: $slug }) {
      ...rentaFija
    }
  }
`;
